import { logRequest, logResponse, logError } from "helpers/loggerData";

export interface IData {
    data: {
        attributes: {
            conditionsCreditAmount: number;
            conditionsMarginCall: string;
            conditionsMaxLeverage: string;
            conditionsMaxPositionOrOrders: string;
            conditionsMaxPositionSize: string;
            conditionsNumberOfInstruments: string;
            conditionsStopOut: string;
            createdAt: string;
            description: string;
            locale: string;
            publishedAt: string;
            title: string;
            updatedAt: string;
            welcomeAccountTypeId: number;
        };
        id: number;
    };
    meta: any;
}

export const getWelcomeAccount = async (): Promise<IData | undefined> => {
    const logData = {
        desc: "Welcome Account",
        method: "GET",
        url: "/strapi/promo-welcome-account?plugins[i18n][locale]=en",
    };
    const { url, method } = logData;

    logRequest(logData);

    try {
        const response = await fetch(url, {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
        },
        });

        logResponse(logData, response);

        const json = await response.json();

        return json;

    } catch (error: unknown) {
        console.error(error);
        logError(logData, error);
    }
};
