import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Icon } from "../../icon/icon.component";
import { StyledSelect } from "../select/select.styled";
import { Typography } from "components/atoms/typography/typography.component";
import { StylesToInput } from "styles/basic.styled";
import { InputStyleTypes } from "./input.types";
import { media } from "lib/styled-components/variables";

interface IProps {
    error?: boolean;
    small?: boolean;
}

interface IInput extends IProps {
    $styleType: InputStyleTypes;
}

export const StyledInput = styled.input<IInput>`
    ${StylesToInput};
    width: 100%;
    border: 1px solid ${({theme, error}) => error ? theme.red?.[600] : theme.gray?.[500]};
    outline: none;
    margin-bottom: -1px;
    background-color: ${({theme}) => theme.bg?.default};
    color: ${({theme}) => theme.text?.default};
    ${({ $styleType }) => {
        switch ($styleType) {
            case InputStyleTypes.DEFAULT:
                return css<{small?: boolean;}>`
                    border-radius: 0;
                    height: ${({ small }) => small ? 60 : 78}px;
                    padding: ${({ small }) => small ? `${spacing(16)} ${spacing(8)}` : `${spacing(16)} ${spacing(40)} ${spacing(8)} ${spacing(8)}`};
                    border-radius: ${({ small }) => small && "12px"};
                `;
            case InputStyleTypes.AUTH:
                return css`
                    height: 68px;
                    border-radius: 8px;
                    padding: ${spacing(16)} ${spacing(8)};
                    ${media.smMax`
                        height: 60px;
                    `}
                `;
        }
    }};
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    &::-webkit-search-cancel-button {
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        right: 8px;
        bottom: 8px;
        height: 48px;
        width: 48px;
    }
`;

export const StyledLabel = styled.label<{$styleType: InputStyleTypes}>`
    ${StylesToInput};
    color: ${({theme}) => theme.text?.muted};
    ${({ $styleType }) => {
        switch ($styleType) {
            case InputStyleTypes.DEFAULT:
                return css`
                    position: absolute;
                    top: 4px;
                    left: 8px;
                `;
            case InputStyleTypes.AUTH:
                return css`
                    display: block;
                    text-align: left;
                    margin-bottom: 8px;
                `;
        }
    }};
`;

export const StyledErrorText = styled(Typography)<IProps>`
    position: absolute;
    left: 8px;
    bottom: 4px;
    color: ${({theme}) => theme.red?.[800]};
    transition: 0.3s;
    opacity: ${({error}) => error ? 1 : 0};
`;

export const StyledInputWrapper = styled.div<IProps>`
    position: relative;
    z-index: ${({error}) => error ? 2 : 1};
    line-height: 0;
`;

export const StyledInputList = styled.div`
    position: relative;
    ${StyledInputWrapper} {
        &:first-child {
            ${StyledInput},
            ${StyledSelect} {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
        &:last-child {
            ${StyledInput},
            ${StyledSelect} {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
`;

export const StyledPasswordButton = styled.div<{$styleType: InputStyleTypes}>`
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    position: absolute;
    right: 12px;
    bottom: ${({ $styleType }) => $styleType === InputStyleTypes.DEFAULT ? 12 : 18}px;
    cursor: pointer;
`;

export const StyledPasswordIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: ${({theme}) => theme.text?.muted};
`;

export const StyledDateIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    bottom: 18px;
    pointer-events: none;
    fill: ${({theme}) => theme.text?.muted};
    color: ${({theme}) => theme.text?.muted};
`;
