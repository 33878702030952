import React, { HTMLAttributeAnchorTarget } from "react";
import Link, { LinkProps } from "next/link";
import { StyledLink } from "./custom-link.styled";

export interface ILinkProps extends LinkProps {
    className?: string;
    target?: HTMLAttributeAnchorTarget;
    children?: JSX.Element | string;
    id?: string;
}

export const CustomLink: React.FC<ILinkProps> = ({
    children,
    href,
    target,
    className,
    id,
    ...rest
}) => {
    return (
        <Link href={href} passHref {...rest}>
            <StyledLink target={target} className={className} id={id}>
                {children}
            </StyledLink>
        </Link>
    );
};
