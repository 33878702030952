const postfix = "__UUID_SHORT__";

export const COOKIES_NAMES = {
    language: `lang_${postfix}`,
    theme: `theme_${postfix}`,
    cashback: `cashback_${postfix}`,
    partnership: `partnership_${postfix}`,
    links: `links_${postfix}`,
    entryPointParams: "entryPointParams",
    idStatus: "_status",
    tour_popup: `tour_popup_${postfix}`,
    deposit: `deposit_${postfix}`,
    socialLoginError: `socialLoginError_${postfix}`,
    socialConnect: `socialConnect_${postfix}`,
    landingAuth: `landingAuth_${postfix}`,
};

export const COOKIES_ENDLESS_OPTIONS = {
    expires: (() => {
        const tenYears = new Date();
        tenYears.setFullYear(tenYears.getFullYear() + 10);
        return tenYears;
    })(),
    path: "/",
};

export const COOKIES_MONTH_OPTIONS = {
    expires: (() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        return date;
    })(),
    path: "/",
};

export const COOKIES_CROSSDOMAIN_OPTIONS = {
    expires: (() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        return date;
    })(),
    path: "/",
    domain: ".amega.finance",
};

export const COOKIES_DELETE_OPTIONS = {
    expires: (() => {
        const date = new Date();
        date.setMonth(date.getDay() - 1);
        return date;
    })(),
    path: "/",
};

export const PASSWORD_REGEXP = /^([A-z\d!"#№$%&'()*+,-./:;<=>?@[\]^_`{|}~])+$/;
