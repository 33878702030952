import defaultTheme from "./default.theme";
import { checkedColor } from "helpers/conversion-style";
import { darkColor, listColor } from "lib/tokens/ui.styled";

const theme: typeof defaultTheme = {
    bg: {
        page: checkedColor(darkColor.base.bg.page.value),
        section: checkedColor(darkColor.base.bg.section.value),
        default: checkedColor(darkColor.base.bg.default.value),
        accent: checkedColor(darkColor.base.bg.accent.value),
        defaultSemiTransparent: checkedColor(darkColor.base.bg.defaultSemiTransparent.value),
    },
    text: {
        default: checkedColor(darkColor.base.text.default.value),
        muted: checkedColor(darkColor.base.text.muted.value),
    },
    gray: {
        100: checkedColor(darkColor.base.gray[100].value),
        200: checkedColor(darkColor.base.gray[200].value),
        300: checkedColor(darkColor.base.gray[300].value),
        400: checkedColor(darkColor.base.gray[400].value),
        500: checkedColor(darkColor.base.gray[500].value),
        600: checkedColor(darkColor.base.gray[600].value),
        700: checkedColor(darkColor.base.gray[700].value),
        800: checkedColor(darkColor.base.gray[800].value),
        900: checkedColor(darkColor.base.gray[900].value),
        1000: checkedColor(darkColor.base.gray[1000].value),
        1100: checkedColor(darkColor.base.gray[1100].value),
        1200: checkedColor(darkColor.base.gray[1200].value),
        1300: checkedColor(darkColor.base.gray[1300].value),
        1400: checkedColor(darkColor.base.gray[1400].value),
        1500: checkedColor(darkColor.base.gray[1500].value),
    },
    green: {
        100: checkedColor(listColor.green[100].value),
        200: checkedColor(listColor.green[200].value),
        300: checkedColor(listColor.green[300].value),
        400: checkedColor(listColor.green[400].value),
        800: checkedColor(listColor.green[800].value),
        900: checkedColor(listColor.green[900].value),
        1000: checkedColor(listColor.green[1000].value),
        1100: checkedColor(listColor.green[1100].value),
        1400: checkedColor(listColor.green[1400].value),
        1500: checkedColor(listColor.green[1500].value),
        dark: "#082B08",
    },
    red: {
        200: checkedColor(listColor.red[200].value),
        300: checkedColor(listColor.red[300].value),
        600: checkedColor(listColor.red[600].value),
        800: checkedColor(listColor.red[800].value),
        1000: checkedColor(listColor.red[1000].value),
        1100: checkedColor(listColor.red[1100].value),
        1300: checkedColor(listColor.red[1300].value),
        1400: checkedColor(listColor.green[1400].value),
        1500: checkedColor(listColor.green[1500].value),
    },
    yellow: {
        100: checkedColor(listColor.yellow[100].value),
        200: checkedColor(listColor.yellow[200].value),
        500: checkedColor(listColor.yellow[500].value),
        800: checkedColor(listColor.yellow[800].value),
        900: checkedColor(listColor.yellow[900].value),
        1000: checkedColor(listColor.yellow[1000].value),
        1100: checkedColor(listColor.yellow[1100].value),
        1500: checkedColor(listColor.yellow[1500].value),
    },
    blue: {
        100: checkedColor(listColor.blue[100].value),
    },
    base: {
        white: checkedColor(listColor.base.white.value),
        black: checkedColor(listColor.base.black.value),
        text: checkedColor(listColor.base.text.value),
    },
    purple: {
        100:  checkedColor(listColor.purple[100].value),
        200:  checkedColor(listColor.purple[200].value),
        600:  checkedColor(listColor.purple[600].value),
        700:  checkedColor(listColor.purple[700].value),
        800:  checkedColor(listColor.purple[800].value),
    },
    popup: {
        default: checkedColor(darkColor.popup.default.value.color),
        success: checkedColor(darkColor.popup.success.value.color),
        error: checkedColor(darkColor.popup.error.value.color),
    },
    card: {
        default: checkedColor(darkColor.card.default.default.value.color),
        small: checkedColor(darkColor.card.small.default.value.color),
    },
    components: {
        error_bg: checkedColor(darkColor.base.components.accent.error.bg.value),
        error_text: checkedColor(darkColor.base.components.accent.error.text.value),
        warning_bg: checkedColor(darkColor.base.components.accent.warning.bg.value),
        warning_text: checkedColor(darkColor.base.components.accent.warning.text.value),
        success_bg: checkedColor(darkColor.base.components.accent.success.bg.value),
        success_text: checkedColor(darkColor.base.components.accent.success.text.value),
        success_border: checkedColor(darkColor.base.components.accent.success.border.value),
        card_border: checkedColor(darkColor.base.components.card.border.value),
        divider: checkedColor(darkColor.base.components.list.item.divider.value),
        purple_text: checkedColor(darkColor.base.components.accent.purple.text.value),
        purple_bg: checkedColor(darkColor.base.components.accent.purple.bg.value),
        purple_border: checkedColor(darkColor.base.components.accent.purple.border.value),
        blue_bg: checkedColor(darkColor.base.components.accent.blue.bg.value),
        blue_border: checkedColor(darkColor.base.components.accent.blue.border.value),
        blue_text: checkedColor(darkColor.base.components.accent.blue.text.value),
        accent__default_border: checkedColor(darkColor.base.components.accent.default.border.value),
        accent__dark_border: checkedColor(darkColor.base.components.accent.dark.border.value),
    },
    checkbox: {
        default: checkedColor(darkColor.form.checkbox.default.value.color),
        checked: checkedColor(darkColor.form.checkbox.checked.value.color),
    },
};

export default theme;
