import React, { PropsWithChildren, ReactNode } from "react";
import { TypographyTypes } from "./typography.types";
import {
    StyledH1,
    StyledH2,
    StyledH3,
    StyledH4,
    StyledH5,
    StyledH6,
    StyledHuge,
    StyledLarge,
    StyledBase,
    StyledSmall,
    StyledButton,
    StyledBannerTitle,
    StyledAccent,
    StyledDefault,
} from "./typography.styled";

export interface ITypographyProps extends PropsWithChildren {
    variant: TypographyTypes;
    className?: string;
    id?: string;
}

export const Typography: React.FC<ITypographyProps> = ({
    variant,
    className,
    children,
    id,
}) => {
    const typography: Record<TypographyTypes, ReactNode> = {
        h1: <StyledH1 id={id} className={className}>{children}</StyledH1>,
        h2: <StyledH2 id={id}  className={className}>{children}</StyledH2>,
        h3: <StyledH3 id={id}  className={className}>{children}</StyledH3>,
        h4: <StyledH4 id={id}  className={className}>{children}</StyledH4>,
        h5: <StyledH5 id={id}  className={className}>{children}</StyledH5>,
        h6: <StyledH6 id={id}  className={className}>{children}</StyledH6>,
        huge: <StyledHuge id={id}  className={className}>{children}</StyledHuge>,
        large: <StyledLarge id={id}  className={className}>{children}</StyledLarge>,
        base: <StyledBase id={id}  className={className}>{children}</StyledBase>,
        small: <StyledSmall id={id}  className={className}>{children}</StyledSmall>,
        button: <StyledButton id={id}  className={className}>{children}</StyledButton>,
        bannerTitle: (
            <StyledBannerTitle id={id}  className={className}>
                {children}
            </StyledBannerTitle>
        ),
        accent: <StyledAccent id={id}  className={className}>{children}</StyledAccent>,
        default: (
            <StyledDefault id={id}  className={className}>{children}</StyledDefault>
        ),
    };
    return <>{typography[variant]}</>;
};
