import styled from "styled-components";
import { Modals } from "../modals.component";
import { Typography } from "components/atoms/typography/typography.component";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { Icon } from "components/atoms/icon/icon.component";
import { StyledTitleBox, StyledButtonClose, StyledTitleIconBox } from "../modals.styled";
import { media } from "lib/styled-components/variables";

export const StyledWelcomeModals = styled(Modals)`
    max-width: calc(100vw - 30px);
    width: 1250px;
    top: 0;
    height: 750px;
    margin: auto;
    border-radius: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    overflow-y: auto;
    ${StyledTitleIconBox} {
        display: none;
    }
    ${StyledTitleBox} {
        grid-template-columns: 1fr;
        position: absolute;
        top: 23px;
        right: 23px;
        padding: 0;
    }
    ${StyledButtonClose} {
        width: 51px;
        height: 51px;
    }
    ${media.mdMax`
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        grid-template-columns: 1fr;
        ${StyledTitleBox} {
            top: 20px;
            right: 20px;
        }
    `}
`;

export const StyledUnlockBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 36px;
    background-color: ${({ theme }) => theme.gray?.[100]};
    padding: 36px 36px 48px;
    ${media.mdMax`
        background-color: inherit;
        padding: 0;
        row-gap: 28px;
        margin-bottom: 60px;
    `}
`;

export const StyledUnlockMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 36px;
    text-align: center;
    width: 100%;
    ${media.mdMax`
        background-color: ${({ theme }) => theme.components.purple_bg};
        border-radius: 0 0 30px 30px;
        padding: 80px 20px 32px;
        row-gap: 24px;
    `}
`;

export const StyledUnlockList = styled.ul`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    ${media.mdMax`
        padding: 0 16px;
    `}
`;

export const StyledUnlockItem = styled.li`
    display: grid;
    grid-template-columns: 37px 1fr;
    gap: 14px;
`;

export const StyledUnlockIcon = styled(Icon)`
    width: 128px;
    height: 128px;
`;

export const StyledUnlockNum = styled(Typography)`
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.purple[800]};
    color: ${({ theme }) => theme.base.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledUnlockText = styled(Typography)`
    padding-top: 8px;
`;

export const StyledUnlockButton = styled(ButtonLink)`
    max-width: 420px;
    ${media.mdMax`
        display: none;
    `}
`;

export const StyledInfoBox = styled.div`
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mdMax`
        padding: 0;
        margin-bottom: 74px;
    `}
`;

export const StyledInfoList = styled.ul`
    list-style: none;
    padding: 18px 12px;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.components.card_border};
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    width: 100%;
    ${media.mdMax`
        border: none;
        padding: 8px 12px;
    `}
`;

export const StyledInfoItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 5px;
    color: ${({ theme }) => theme.text.default};
    text-align: right;
`;

export const StyledInfoQuest = styled(Typography)`
    color: ${({ theme }) => theme.text.muted};
    text-align: left;
`;

export const StyledInfoLine = styled.div`
    height: 1px;
    min-width: 30px;
    flex: 1;
    background-color: ${({ theme }) => theme.components.divider};
`;

export const StyledButtonMobile = styled(ButtonLink)`
    max-width: 420px;
`;

export const StyledButtonBox = styled.div`
    display: none;
    padding: 0 20px 20px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    ${media.mdMax`
        display: flex;
    `}
`;
