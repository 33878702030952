import { logRequest, logResponse, logError } from "helpers/loggerData";
import { statusTokenSignOut } from "helpers/statusTokenSignOut";

export interface IProfile {
    birthDate: string;
    canCreateIbLinks: boolean;
    canRequestIb: boolean;
    cid: number;
    clientType: string;
    country: string;
    email: string;
    emailVerified: boolean;
    financialPermissions: string[];
    firstDepositId: number;
    firstDepositDate: string | Date;
    firstName: string;
    ibLinksRestricted: boolean;
    id: number;
    isIb: boolean;
    isVerified: boolean;
    language: string;
    lastDepositDate: string;
    lastDepositId: number;
    lastName: string;
    lastTradedAt: string;
    phone: string;
    phoneVerified: boolean;
    registrationDate: string;
    smsNotificationEnabled: boolean;
    twoFactorAuthEnabled: boolean;
    customFields: any;
    notificationPreferences: any;
};

export const getProfileClient = async (
    token: string,
    language?: string,
): Promise<IProfile | undefined> => {

    const logData = {
      desc: "Profile",
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API}/client-api/profile`,
    };
    const { url, method } = logData;

    try {
        logRequest(logData);

        const response = await fetch(url,
            {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        const { ok: responseOk, status: responseStatus } = response;

        logResponse(logData, response);

        void statusTokenSignOut(responseStatus);

        if ( responseOk ) {
            const json = await response.json();
            return json;
        }
    } catch (error: unknown) {
        console.error(error);
        logError(logData, error);
    }
};
