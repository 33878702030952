import React, { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { ButtonSize } from "components/atoms/button-link/button-link.types";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import { useRouter } from "next/router";
import { getWelcomeAccount, IData } from "lib/hooks/strapi/getWelcomeAccount";
import { useModals } from "components/providers/modals/modals.provider";
import {
    StyledWelcomeModals,
    StyledUnlockBox,
    StyledUnlockList,
    StyledUnlockItem,
    StyledUnlockNum,
    StyledUnlockText,
    StyledUnlockButton,
    StyledUnlockIcon,
    StyledInfoBox,
    StyledInfoList,
    StyledInfoItem,
    StyledInfoQuest,
    StyledInfoLine,
    StyledUnlockMobile,
    StyledButtonBox,
    StyledButtonMobile,
} from "./welcome.account.styled";

export interface IProps {
    closeModal: () => void;
}

interface IList {
    quest: string;
    answer: string;
}

export const WelcomeAccountModals: React.FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation("banners");
    const [com] = useTranslation("common");
    const [ dataInfo, setDataInfo ] = useState<IData | undefined>();
    const { push } = useRouter();
    const { closeWelcomeAccountModal } = useModals();

    const handlerClickVerify = () => {
        void push("/verify-profile");
        closeWelcomeAccountModal();
    };

    const infoList: IList[] = [
        {
            quest: __("welcome-account.maxLeverage"),
            answer: dataInfo?.data.attributes.conditionsMaxLeverage || "",
        },
        {
            quest: __("welcome-account.marginCall"),
            answer: dataInfo?.data.attributes.conditionsMarginCall || "",
        },
        {
            quest: __("welcome-account.stopLevel"),
            answer: dataInfo?.data.attributes.conditionsStopOut || "",
        },
        {
            quest: __("welcome-account.maxPositions"),
            answer: dataInfo?.data.attributes.conditionsMaxPositionOrOrders || "",
        },
        {
            quest: __("welcome-account.investment"),
            answer: dataInfo?.data.attributes.conditionsMaxPositionSize || "",
        },
        {
            quest: __("welcome-account.numberInstruments"),
            answer: dataInfo?.data.attributes.conditionsNumberOfInstruments || "",
        },
        // {
        //     quest: "Other instruments",
        //     answer: "Read-only",
        // },
    ];

    useEffect(() => {
        void getWelcomeAccount().then((data) => setDataInfo(data));
    }, []);

    return (
        <StyledWelcomeModals
            numberOverlay={0.1}
            closeModal={closeModal}
        >
            <StyledUnlockBox>
                <StyledUnlockMobile>
                    <StyledUnlockIcon icon={IconTypes.WELCOME_ACCOUNT_MODAL} />
                    <Typography variant={TypographyTypes.H1}>{__("welcome-account.modal.title")}</Typography>
                    <Typography variant={TypographyTypes.LARGE}>{__("welcome-account.modal.description")}</Typography>
                </StyledUnlockMobile>
                <StyledUnlockList>
                    <StyledUnlockItem>
                        <StyledUnlockNum variant={TypographyTypes.H4}>1</StyledUnlockNum>
                        <StyledUnlockText variant={TypographyTypes.DEFAULT}>{__("welcome-account.modal.list1")}</StyledUnlockText>
                    </StyledUnlockItem>
                    <StyledUnlockItem>
                        <StyledUnlockNum variant={TypographyTypes.H4}>2</StyledUnlockNum>
                        <StyledUnlockText variant={TypographyTypes.DEFAULT}>{__("welcome-account.modal.list2")}</StyledUnlockText>
                    </StyledUnlockItem>
                    <StyledUnlockItem>
                        <StyledUnlockNum variant={TypographyTypes.H4}>3</StyledUnlockNum>
                        <StyledUnlockText variant={TypographyTypes.DEFAULT}>{__("welcome-account.modal.list3")}</StyledUnlockText>
                    </StyledUnlockItem>
                </StyledUnlockList>
                <StyledUnlockButton
                    variant={ButtonTypes.BLACK}
                    size={ButtonSize.BIG}
                    onClick={handlerClickVerify}
                    text={__("welcome-account.button")}
                />
            </StyledUnlockBox>
            <StyledInfoBox>
                <StyledInfoList>
                    {infoList.map((item) => (
                        <StyledInfoItem key={item.quest.replace(/ /g,"_")}>
                            <StyledInfoQuest variant={TypographyTypes.DEFAULT}>{item.quest}</StyledInfoQuest>
                            <StyledInfoLine />
                            <Typography variant={TypographyTypes.DEFAULT}>{item.answer}</Typography>
                        </StyledInfoItem>
                    ))}
                </StyledInfoList>
            </StyledInfoBox>
            <StyledButtonBox>
                <StyledButtonMobile
                    variant={ButtonTypes.BLACK}
                    size={ButtonSize.BIG}
                    onClick={() => push("/verify-profile")}
                    text={__("welcome-account.button")}
                />
                <StyledButtonMobile
                    variant={ButtonTypes.SECONDARY}
                    size={ButtonSize.BIG}
                    onClick={closeModal}
                    text={com("components.close")}
                />
            </StyledButtonBox>
        </StyledWelcomeModals>
    );
};
