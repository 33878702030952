import { AppProps } from "next/app";
import { useEffect } from "react";
// import ApolloProvider from "./apollo/apollo.provider";
import I18NextProvider from "./i18next/i18next.provider";
import StyledProvider from "./styled/styled.provider";
import ThemeProvider from "./theme/theme.provider";
import { ModalsProvider } from "./modals/modals.provider";
import { LanguageProvider } from "./language/language.provider";
import { SessionProvider } from "next-auth/react";
import posthog from "posthog-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PostHogProvider } from "posthog-js/react";
import mixpanel from "mixpanel-browser";
// import UserProvider from "./user/user.provider";

// const ToastDynamicProvider = dynamic(
//     async () => (await import("./toast.provider")).default,
//     { ssr: false },
// );

const CompositeProvider: React.FC<React.PropsWithChildren<AppProps>> = (
    props,
) => {
    const { pageProps, children } = props;

    useEffect(() => {
        mixpanel.init(`${process.env.MIXPANEL_TOKEN}`, {
            debug: true,
            track_pageview: false,
            persistence: "localStorage",
        });

        posthog.init(`${process.env.POSTHOG_API_KEY}`, {
            api_host: "https://eu.posthog.com",
            autocapture: false,
            capture_pageview: false,
            capture_pageleave: false,
        });
    }, [])

    return (
        <>
            <I18NextProvider {...props}>
                {/* <ApolloProvider pageProps={pageProps}> */}
                {/* <UserProvider userGuard={pageProps._userGuard} /> */}
                <SessionProvider session={pageProps.session}>
                    <GoogleOAuthProvider clientId={process.env.GOOGLE_WEB_CLIENT_ID || ""}>
                        <PostHogProvider client={posthog}>
                            <ThemeProvider>
                                <LanguageProvider>
                                    <StyledProvider sheet={pageProps.sheet}>
                                        <ModalsProvider>{children}</ModalsProvider>
                                    </StyledProvider>
                                </LanguageProvider>
                            </ThemeProvider>
                        </PostHogProvider>
                    </GoogleOAuthProvider>
                </SessionProvider>
                {/* <UserProvider /> */}
                {/* </ApolloProvider> */}
            </I18NextProvider>
            {/* <ToastDynamicProvider /> */}
        </>
    );
};

export default CompositeProvider;
