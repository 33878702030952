import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Icon } from "components/atoms/icon/icon.component";
import { media } from "lib/styled-components/variables";

interface IProps {
    isAnimation: boolean;
    $isSideModal?: boolean;
}

interface IOverlay extends IProps{
    $opacity?: number;
}

export const StyleForSideModal = css<IProps>`
    transform: ${({ isAnimation }) =>
        isAnimation ? "translateX(0)" : "translateX(100%)"};
`;

export const StyleForMainModal = css<IProps>`
    transform: ${({ isAnimation }) =>
        isAnimation ? "translateY(0)" : "translateY(100%)"};
`;

export const StyledOverlay = styled.div<IOverlay>`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: ${({ isAnimation, $opacity }) => isAnimation ? `rgba(0, 0, 0, ${$opacity ? $opacity : "0.5"})` : "rgba(0, 0, 0, 0)"};
    transition: 0.3s
        ${({ isAnimation }) => (isAnimation ? "ease-in" : "ease-out")};
`;

export const StyledModalWindow = styled.div<IProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.bg?.page};
    color: ${({ theme }) => theme.text?.default};
    border-radius: 24px 24px 0 0;
    transition: 0.3s
        ${({ isAnimation }) => (isAnimation ? "ease-in" : "ease-out")};
    transform: translateY(${({ isAnimation }) => (isAnimation ? 0 : "200%")});
    margin: 0 auto;
    width: calc(100vw - 16px);
    max-height: calc(100% - 16px);
    ${StyleForMainModal};
    ${media.sm<IProps>`
        ${({ $isSideModal }) =>
            $isSideModal ? StyleForSideModal : StyleForMainModal};
    `}
`;

export const StyledModalFixed = styled(StyledModalWindow)`
    position: fixed;
    z-index: 2;
`;

export const StyledTitleBox = styled.div`
    padding: ${spacing(16)} ${spacing(16)} ${spacing(32)};
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    gap: 8px;
`;

export const StyledButtonClose = styled.button`
    border: none;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.gray?.[100]};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.default};
`;

export const StyledButtonCloseBig = styled.button`
    border: none;
    background-color: ${({ theme }) => theme.text?.default};
    color: ${({ theme }) => theme.bg?.default};
    border-radius: 12px;
    padding: ${spacing(8)};
    text-align: center;
    width: 100%;
    cursor: pointer;
`;

export const StyledTitleIconBox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledIconBox = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.gray?.[800]};
    border-radius: 50%;
`;

export const StyledTitleIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.gray?.[200]};
`;
