import { createContext, useCallback, useContext, useState } from "react";
import { IRows } from "lib/hooks/cashbackBreakdown";
import { IconTypes } from "components/atoms/icon/icon.types";
import { ButtonIcon } from "components/atoms/button-link/button-link.types";
import { LanguageModals } from "components/organisms/modals/language/language-modals.component";
import { CookiesModals } from "components/organisms/modals/cookies/cookies-modals.component";
import { ProfileMenuModals } from "components/organisms/modals/profile-menu/profile-menu.component";
import { NotificationModals } from "components/organisms/modals/notification/notification-modals.component";
import { DisclaimerModals } from "components/organisms/modals/disclaimer/disclaimer-modals.component";
import { AccountDetailsServerInfoModal } from "components/organisms/modals/account-details/server-info-modal.component";
import { AccountDetailsMenuModal } from "components/organisms/modals/account-details/menu-modal.component";
import { AccountDetailsModal } from "components/organisms/modals/account-details/modal.component";
import { SumSubModals } from "components/organisms/modals/sumsub/sumsub-modals.component";
import { AccountInfoModals } from "components/organisms/modals/account-info/account-info-modals.component";
import { ConnectInfoModals } from "components/organisms/modals/connect-info/connect-info-modals.component";
import {
    SocialSignUpModals,
    ISocialModals,
} from "components/organisms/modals/social-signup/social-signup-modals.component";
import { CashbackInfoModals } from "components/organisms/modals/cashback-info/cashback-info-modals.component";
import { IAccount } from "lib/hooks/accountUser";
import {
    CSVModals,
    ICSVCashback,
    ICSVHeader,
    ICSVPartnership,
    ICSVHistory,
} from "components/organisms/modals/csv/csv-modal.component";
import { PartnershipInfoModals } from "components/organisms/modals/partnership-info/partnership-info-modals.component";
import { ReferralsLevelModals } from "components/organisms/modals/referrals-level/referrals-level-modals.component";
import { CashbackHistoryModals } from "components/organisms/modals/cashback-history/cashback-history-modals.component";
import { PartnershipReferralsModals } from "components/organisms/modals/partnership-referrals/partnership-referrals-modals.component";
import { QrLinkModals } from "components/organisms/modals/qr-link/qr-link-modals.component";
import { IRowsReferrals } from "lib/hooks/ib/referrals";
import { DepositInfoModals } from "components/organisms/modals/deposit-info/deposit-info-modals.component";
import { DeleteModals } from "components/organisms/modals/delete/delete.component";
import { PreviewModals } from "components/organisms/modals/preview/preview.component";
import { TransferInfoModals } from "components/organisms/modals/transfer/info/transfer-info-modals.component";
import { WelcomeAccountModals } from "components/organisms/modals/welcome-account/welcome-account.component";
import {
    StatusBenefitsModals,
    StatusBenefitsTypes,
} from "components/organisms/modals/benefits-status/benefits-status-modals.component";
import { NewWalletModal } from "components/organisms/modals/new-wallet";
import { IncompleteTransaction } from "components/organisms/modals/incomplete-transactions";

export interface IModalsContext {
    openIncompleteTransactionModal: (config: any) => void;
    openLanguageModal: () => void;
    openCookiesModal: () => void;
    openProfileMenuModal: () => void;
    openDisclaimerModals: () => void;
    openAccountInfoModals: (account: string) => void;
    openSumSubModals: (sumsub: string) => void;
    openNewWalletNotificationModal: () => void;
    openNotificationModal: (
        text: string,
        icon?: string,
        error?: boolean,
        title?: string,
    ) => void;
    openSocialSignUpModal: (
        title: string,
        buttonText: string,
        icon: ButtonIcon,
    ) => void;
    openAccountDetailsModal: (
        data: IAccount | undefined,
        user_id: number,
    ) => void;
    openAccountDetailsServerInfoModal: () => void;
    openAccountDetailsMenuModal: (
        icon: IconTypes,
        title: string,
        sid: string,
    ) => void;
    openCashbackInfoModals: () => void;
    openPartnershipInfoModals: () => void;
    openCashbackHistoryModals: (data: IRows[]) => void;
    closeCashbackHistoryModals: () => void;
    openCSVModal: (
        title: string,
        data: ICSVCashback[] | ICSVPartnership[] | ICSVHistory[],
        headerData: ICSVHeader[],
    ) => void;
    openReferralsLevelModals: () => void;
    openQrLinkModals: (link: string) => void;
    openDeleteModals: (
        name: string,
        id: number,
        onDelete?: (
            id: number,
            closeHandler: () => void,
        ) => void | Promise<void> | undefined,
        onClose?: () => void | Promise<void> | undefined,
    ) => void;
    openPreviewModals: (link: string, title: string) => void;
    openReferralsModals: (data: IRowsReferrals[], currency: string) => void;
    closeReferralsModals: () => void;
    openDepositInfoModals: (title: string, text: string) => void;
    openTransferInfoModals: (title: string, desc: string) => void;
    openStatusBenefits: (status: StatusBenefitsTypes) => void;
    openConnectModal: () => void;
    openWelcomeAccountModal: () => void;
    closeWelcomeAccountModal: () => void;
}

interface ISocialSignUpProps extends ISocialModals {
    visible: boolean;
}

const noop = () => {
    return undefined;
};

const defaultModalsContext: IModalsContext = {
    openNewWalletNotificationModal: noop,
    openIncompleteTransactionModal: noop,
    openLanguageModal: noop,
    openCookiesModal: noop,
    openSocialSignUpModal: noop,
    openProfileMenuModal: noop,
    openNotificationModal: noop,
    openDisclaimerModals: noop,
    openSumSubModals: noop,
    openAccountInfoModals: noop,
    openAccountDetailsModal: noop,
    openAccountDetailsServerInfoModal: noop,
    openAccountDetailsMenuModal: noop,
    openCashbackInfoModals: noop,
    openCashbackHistoryModals: noop,
    closeCashbackHistoryModals: noop,
    openCSVModal: noop,
    openPartnershipInfoModals: noop,
    openReferralsLevelModals: noop,
    openQrLinkModals: noop,
    openDeleteModals: noop,
    openPreviewModals: noop,
    openReferralsModals: noop,
    closeReferralsModals: noop,
    openDepositInfoModals: noop,
    openTransferInfoModals: noop,
    openStatusBenefits: noop,
    openConnectModal: noop,
    openWelcomeAccountModal: noop,
    closeWelcomeAccountModal: noop,
};

const ModalsContext = createContext<IModalsContext>(defaultModalsContext);

export const useModals = () => {
    return useContext(ModalsContext);
};

interface IProps extends React.PropsWithChildren {}

export const ModalsProvider: React.FC<IProps> = ({ children }) => {
    // LANGUAGE MODAL
    const [isOpenLanguage, setOpenLanguage] = useState<boolean>(false);

    const openLanguageModal = useCallback(() => setOpenLanguage(true), []);
    const closeLanguageModal = useCallback(() => setOpenLanguage(false), []);

    // COOKIES MODAL
    const [isOpenCookies, setOpenCookies] = useState<boolean>(false);

    const openCookiesModal = useCallback(() => setOpenCookies(true), []);
    const closeCookiesModal = useCallback(() => setOpenCookies(false), []);

    // PROFILE MENU MODAL
    const [isOpenProfileMenu, setOpenProfileMenu] = useState<boolean>(false);

    const openProfileMenuModal = useCallback(
        () => setOpenProfileMenu(true),
        [],
    );
    const closeProfileMenuModal = useCallback(
        () => setOpenProfileMenu(false),
        [],
    );

    // SOCIAL SIGNUP MODAL
    const [socialSignUpModal, setSocialSignUpModal] =
        useState<ISocialSignUpProps>({
            visible: false,
            title: "",
            buttonText: "",
            icon: ButtonIcon.FACEBOOK,
        });

    const openSocialSignUpModal = useCallback(
        (title: string, buttonText: string, icon: ButtonIcon) => {
            setSocialSignUpModal({
                visible: true,
                title,
                buttonText,
                icon,
            });
        },
        [],
    );

    const closeSocialSignUpModal = () => {
        setSocialSignUpModal((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // NOTIFICATION MODAL
    const [notificationModals, setNotificationModals] = useState({
        visible: false,
        text: "",
        icon: "",
        error: false,
        title: "",
    });

    const openNotificationModal = useCallback(
        (text: string, icon?: string, error?: boolean, title?: string) => {
            setNotificationModals({
                visible: true,
                text,
                icon: icon ? icon : "",
                error: error ? error : false,
                title: title ? title : "",
            });
        },
        [],
    );

    const closeNotificationModal = () => {
        setNotificationModals((prev) => ({
            ...prev,
            visible: false,
            text: "",
            icon: "",
            error: false,
            title: "",
        }));
    };

    // DISCLAIMER MODAL
    const [isOpenDisclaimer, setOpenDisclaimer] = useState<boolean>(false);

    const openDisclaimerModals = useCallback(() => setOpenDisclaimer(true), []);
    const closeDisclaimerModals = useCallback(
        () => setOpenDisclaimer(false),
        [],
    );

    // AccountDetails ServerInfo MODAL
    const [isAccountDetailsServerInfoModal, setAccountDetailsServerInfoModal] =
        useState<boolean>(false);

    const openAccountDetailsServerInfoModal = useCallback(
        () => setAccountDetailsServerInfoModal(true),
        [],
    );
    const closeAccountDetailsServerInfoModal = useCallback(
        () => setAccountDetailsServerInfoModal(false),
        [],
    );

    // AccountDetails MODAL
    const [accountDetailsModal, setAccountDetailsModal] = useState<{
        visible: boolean;
        data: IAccount | undefined;
        user_id: number;
    }>({
        visible: false,
        data: undefined,
        user_id: 0,
    });

    const openAccountDetailsModal = useCallback(
        (data: IAccount | undefined, user_id: number) =>
            setAccountDetailsModal({
                data,
                visible: true,
                user_id,
            }),
        [],
    );
    const closeAccountDetailsModal = useCallback(
        () =>
            setAccountDetailsModal((prevState) => ({
                ...prevState,
                visible: false,
            })),
        [],
    );

    // AccountDetails Menu MODAL
    const [accountDetailsMenuModal, setAccountDetailsMenuModal] = useState({
        visible: false,
        icon: IconTypes.ACCOUNT_STANDARD,
        title: "",
        sid: "",
    });

    const openAccountDetailsMenuModal = useCallback(
        (icon: IconTypes, title: string, sid: string) =>
            setAccountDetailsMenuModal({
                icon,
                title,
                sid,
                visible: true,
            }),
        [],
    );
    const closeAccountDetailsMenuModal = useCallback(
        () =>
            setAccountDetailsMenuModal((prevState) => ({
                ...prevState,
                visible: false,
            })),
        [],
    );

    // LANGUAGE MODAL
    const [sumSubModals, setSumSubModals] = useState({
        visible: false,
        sumsub: "",
    });

    const openSumSubModals = useCallback((sumsub: string) => {
        setSumSubModals({
            visible: true,
            sumsub,
        });
    }, []);

    const closeSumSubModals = () => {
        setSumSubModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // COOKIES MODAL
    const [accountInfoModals, setAccountInfoModals] = useState({
        visible: false,
        account: "",
    });

    const openAccountInfoModals = useCallback((account: string) => {
        setAccountInfoModals({
            visible: true,
            account,
        });
    }, []);

    const closeAccountInfoModals = () => {
        setAccountInfoModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // CASHBACK MODAL
    const [isOpenCashback, setOpenCashback] = useState<boolean>(false);

    const openCashbackInfoModals = useCallback(() => setOpenCashback(true), []);
    const closeCashbacInfokModals = useCallback(
        () => setOpenCashback(false),
        [],
    );

    // CASHBACK HISTORY MODAL
    const [isCashbackHistoryModals, setIsCashbackHistoryModals] =
        useState(false);
    const [isCashbackHistoryData, setIsCashbackHistoryData] = useState<IRows[]>(
        [],
    );

    const openCashbackHistoryModals = useCallback((data: IRows[]) => {
        setIsCashbackHistoryModals(true);
        setIsCashbackHistoryData(data);
    }, []);

    const closeCashbackHistoryModals = () => {
        setIsCashbackHistoryModals(false);
    };

    // CSV MODAL
    const [isCSVModals, setIsCSVModals] = useState<{
        visible: boolean;
        title: string;
        data: ICSVCashback[] | ICSVPartnership[] | ICSVHistory[];
        headerData: ICSVHeader[];
    }>({
        visible: false,
        title: "",
        data: [],
        headerData: [],
    });

    const openCSVModal = useCallback(
        (
            title: string,
            data: ICSVCashback[] | ICSVPartnership[] | ICSVHistory[],
            headerData: ICSVHeader[],
        ) => {
            setIsCSVModals({
                visible: true,
                title,
                data,
                headerData,
            });
        },
        [],
    );

    const closeCSVModal = () => {
        setIsCSVModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // PARTNERSHIP MODAL
    const [isOpenPartnership, setOpenPartnership] = useState<boolean>(false);

    const openPartnershipInfoModals = useCallback(
        () => setOpenPartnership(true),
        [],
    );
    const closePartnershipInfoModals = useCallback(
        () => setOpenPartnership(false),
        [],
    );

    // REFERRALS LEVEL MODAL
    const [isReferralsLevelModal, setReferralsLevelModal] =
        useState<boolean>(false);

    const openReferralsLevelModals = useCallback(
        () => setReferralsLevelModal(true),
        [],
    );
    const closeReferralsLevelModals = useCallback(
        () => setReferralsLevelModal(false),
        [],
    );

    // QR LINK MODAL
    const [isQrLinkModals, setQrLinkModals] = useState({
        visible: false,
        link: "",
    });

    const openQrLinkModals = useCallback((link: string) => {
        setQrLinkModals({
            visible: true,
            link,
        });
    }, []);

    const closeQrLinkModals = () => {
        setQrLinkModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // DELETE MODAL
    const [isDeleteModals, setDeleteModals] = useState<{
        visible: boolean;
        name: string;
        id: number;
        onDelete?: (
            id: number,
            closeHandler: () => void,
        ) => void | Promise<void> | undefined;
        onClose?: () => void | Promise<void> | undefined;
    }>({
        visible: false,
        name: "",
        id: 0,
        onDelete: undefined,
        onClose: undefined,
    });

    const openDeleteModals = useCallback(
        (
            name: string,
            id: number,
            onDelete?: (
                id: number,
                closeHandler: () => void,
            ) => void | Promise<void> | undefined,
            onClose?: () => void | Promise<void> | undefined,
        ) => {
            setDeleteModals({
                visible: true,
                name,
                id,
                onDelete,
                onClose,
            });
        },
        [],
    );

    const closeDeleteModals = () => {
        setDeleteModals((prev) => ({
            ...prev,
            visible: false,
            name: "",
            id: 0,
            onDelete: undefined,
            onClose: undefined,
        }));
    };

    // PARTNERSHIP MODAL
    const [isReferralsModals, setReferralsModals] = useState<{
        visible: boolean;
        data: IRowsReferrals[];
        currency: string;
    }>({
        visible: false,
        data: [],
        currency: "",
    });

    const openReferralsModals = useCallback(
        (data: IRowsReferrals[], currency: string) => {
            setReferralsModals(() => ({
                visible: true,
                currency,
                data,
            }));
        },
        [],
    );

    const closeReferralsModals = () => {
        setReferralsModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    const [isPreviewModals, setPreviewModals] = useState({
        visible: false,
        link: "",
        title: "",
    });

    const openPreviewModals = useCallback((link: string, title: string) => {
        setPreviewModals({
            visible: true,
            link,
            title,
        });
    }, []);

    const closePreviewModals = () => {
        setPreviewModals((prev) => ({
            ...prev,
            visible: false,
            link: "",
            title: "",
        }));
    };

    // DEPOSIT INFO MODAL
    const [isDepositInfoModals, setDepositInfoModals] = useState<{
        visible: boolean;
        title: string;
        text: string;
    }>({
        visible: false,
        title: "",
        text: "",
    });

    const openDepositInfoModals = useCallback((title: string, text: string) => {
        setDepositInfoModals({
            visible: true,
            title,
            text,
        });
    }, []);

    const closeDepositInfoModals = () => {
        setDepositInfoModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    const [isTransferInfoModals, setTransferInfoModals] = useState<{
        visible: boolean;
        title: string;
        desc: string;
    }>({
        visible: false,
        title: "",
        desc: "",
    });

    const openTransferInfoModals = useCallback(
        (title: string, desc: string) => {
            setTransferInfoModals({
                visible: true,
                title,
                desc,
            });
        },
        [],
    );

    const closeTransferInfoModals = () => {
        setTransferInfoModals((prev) => ({
            ...prev,
            title: "",
            desc: "",
            visible: false,
        }));
    };

    // BENEFITS MODAL
    const [isStatusBenefitsModals, setIsStatusBenefitsModals] = useState<{
        visible: boolean;
        status: StatusBenefitsTypes;
    }>({
        visible: false,
        status: StatusBenefitsTypes.PENDING,
    });

    const openStatusBenefits = useCallback((status: any) => {
        setIsStatusBenefitsModals({
            visible: true,
            status,
        });
    }, []);

    const closeStatusBenefits = () => {
        setIsStatusBenefitsModals((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // NEW WALLET NOTIFICATION MODAL

    const [isOpenNewWalletNotification, setOpenNewWalletNotification] =
        useState({
            visible: false,
        });

    const openNewWalletNotificationModal = () =>
        setOpenNewWalletNotification((prev) => ({
            ...prev,
            visible: true,
        }));
    const closeNewWalletNotification = () =>
        setOpenNewWalletNotification((prev) => ({
            ...prev,
            visible: false,
        }));

    // Incomplete Transaction Modal
    const [isOpenIncompleteTransaction, setOpenIncompleteTransaction] =
        useState({
            visible: false,
            config: {},
        });

    const openIncompleteTransactionModal = useCallback((config: any) => {
        setOpenIncompleteTransaction({ visible: true, config });
    }, []);

    const closeIncompleteTransactionModal = (config = {}) =>
        setOpenIncompleteTransaction({ visible: false, config });

    // Connect Modal
    const [isOpenConnect, setOpenConnect] = useState(false);

    const openConnectModal = useCallback(() => {
        setOpenConnect(true);
    }, []);

    const closeConnectModal = () => setOpenConnect(false);

    // WELCOME ACCOUNT MODAL
    const [isOpenWelcomeAccount, setOpenWelcomeAccount] = useState<boolean>(false);

    const openWelcomeAccountModal = useCallback(() => setOpenWelcomeAccount(true), []);
    const closeWelcomeAccountModal = useCallback(() => setOpenWelcomeAccount(false), []);

    const context = {
        openIncompleteTransactionModal,
        openNewWalletNotificationModal,
        openLanguageModal,
        openCookiesModal,
        openSocialSignUpModal,
        openProfileMenuModal,
        openNotificationModal,
        openDisclaimerModals,
        openSumSubModals,
        openAccountInfoModals,
        openAccountDetailsModal,
        openAccountDetailsServerInfoModal,
        openAccountDetailsMenuModal,
        openCashbackInfoModals,
        openCashbackHistoryModals,
        closeCashbackHistoryModals,
        openCSVModal,
        openPartnershipInfoModals,
        openReferralsLevelModals,
        openQrLinkModals,
        openDeleteModals,
        openPreviewModals,
        openReferralsModals,
        closeReferralsModals,
        openDepositInfoModals,
        openTransferInfoModals,
        openStatusBenefits,
        openConnectModal,
        openWelcomeAccountModal,
        closeWelcomeAccountModal,
    };

    return (
        <ModalsContext.Provider value={context}>
            {children}
            {isOpenLanguage && (
                <LanguageModals closeModal={closeLanguageModal} />
            )}
            {isOpenCookies && <CookiesModals closeModal={closeCookiesModal} />}
            {isOpenProfileMenu && (
                <ProfileMenuModals closeModal={closeProfileMenuModal} />
            )}
            {socialSignUpModal.visible && (
                <SocialSignUpModals
                    closeModal={closeSocialSignUpModal}
                    title={socialSignUpModal.title}
                    buttonText={socialSignUpModal.buttonText}
                    icon={socialSignUpModal.icon}
                />
            )}
            {notificationModals.visible && (
                <NotificationModals
                    closeModal={closeNotificationModal}
                    text={notificationModals.text}
                    icon={notificationModals.icon}
                    error={notificationModals.error}
                    title={notificationModals.title}
                />
            )}
            {isOpenDisclaimer && (
                <DisclaimerModals closeModal={closeDisclaimerModals} />
            )}
            {sumSubModals.visible && (
                <SumSubModals
                    closeModal={closeSumSubModals}
                    sumsub={sumSubModals.sumsub}
                />
            )}
            {accountInfoModals.visible && (
                <AccountInfoModals
                    closeModal={closeAccountInfoModals}
                    account={accountInfoModals.account}
                />
            )}
            {accountDetailsModal.visible && (
                <AccountDetailsModal
                    closeModal={closeAccountDetailsModal}
                    data={accountDetailsModal.data}
                    user_id={accountDetailsModal.user_id}
                />
            )}
            {isAccountDetailsServerInfoModal && (
                <AccountDetailsServerInfoModal
                    closeModal={closeAccountDetailsServerInfoModal}
                />
            )}
            {accountDetailsMenuModal.visible && (
                <AccountDetailsMenuModal
                    closeModal={closeAccountDetailsMenuModal}
                    icon={accountDetailsMenuModal.icon}
                    title={accountDetailsMenuModal.title}
                    sid={accountDetailsMenuModal.sid}
                />
            )}
            {isOpenCashback && (
                <CashbackInfoModals closeModal={closeCashbacInfokModals} />
            )}
            {isCashbackHistoryModals && (
                <CashbackHistoryModals
                    closeModal={closeCashbackHistoryModals}
                    data={isCashbackHistoryData}
                />
            )}
            {isCSVModals.visible && (
                <CSVModals
                    closeModal={closeCSVModal}
                    title={isCSVModals.title}
                    data={isCSVModals.data}
                    headerData={isCSVModals.headerData}
                />
            )}
            {isOpenPartnership && (
                <PartnershipInfoModals
                    closeModal={closePartnershipInfoModals}
                />
            )}
            {isReferralsLevelModal && (
                <ReferralsLevelModals closeModal={closeReferralsLevelModals} />
            )}
            {isQrLinkModals.visible && (
                <QrLinkModals
                    closeModal={closeQrLinkModals}
                    link={isQrLinkModals.link}
                />
            )}
            {isDeleteModals.visible && (
                <DeleteModals
                    closeModal={closeDeleteModals}
                    name={isDeleteModals.name}
                    id={isDeleteModals.id}
                    onDelete={isDeleteModals.onDelete}
                    onClose={isDeleteModals.onClose}
                />
            )}
            {isPreviewModals.visible && (
                <PreviewModals
                    closeModal={closePreviewModals}
                    link={isPreviewModals.link}
                    title={isPreviewModals.title}
                />
            )}
            {isReferralsModals.visible && (
                <PartnershipReferralsModals
                    closeModal={closeReferralsModals}
                    dataFull={isReferralsModals.data}
                    currency={isReferralsModals.currency}
                />
            )}
            {isDepositInfoModals.visible && (
                <DepositInfoModals
                    closeModal={closeDepositInfoModals}
                    title={isDepositInfoModals.title}
                    text={isDepositInfoModals.text}
                />
            )}
            {isTransferInfoModals.visible && (
                <TransferInfoModals
                    closeModal={closeTransferInfoModals}
                    title={isTransferInfoModals.title}
                    desc={isTransferInfoModals.desc}
                />
            )}
            {isStatusBenefitsModals.visible && (
                <StatusBenefitsModals
                    closeModal={closeStatusBenefits}
                    status={isStatusBenefitsModals.status}
                />
            )}
            {isOpenNewWalletNotification.visible && (
                <NewWalletModal closeModal={closeNewWalletNotification} />
            )}
            {isOpenIncompleteTransaction.visible && (
                <IncompleteTransaction
                    closeModal={closeIncompleteTransactionModal}
                    config={isOpenIncompleteTransaction.config}
                />
            )}
            {isOpenConnect && (
                <ConnectInfoModals closeModal={closeConnectModal} />
            )}
            {isOpenWelcomeAccount && (
                <WelcomeAccountModals closeModal={closeWelcomeAccountModal} />
            )}
        </ModalsContext.Provider>
    );
};
