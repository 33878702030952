import React, { useRef, useState, useEffect } from "react";
import { useOutsideClick } from "hooks/use-outside-click/use-outside-click";
import { IconTypes } from "components/atoms/icon/icon.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { Typography } from "components/atoms/typography/typography.component";
import {
    StyledOverlay,
    StyledModalWindow,
    StyledTitleBox,
    StyledButtonClose,
    StyledButtonIcon,
    StyledButtonCloseBig,
    StyledModalFixed,
    StyledTitleIconBox,
    StyledTitleIcon,
    StyledIconBox,
} from "./modals.styled";

export interface IModals extends React.PropsWithChildren {
    closeModal?: () => void;
    className?: string;
    title?: string | JSX.Element;
    closeButton?: string;
    isOverlay?: boolean;
    overlayClick?: boolean;
    iconTitle?: IconTypes;
    withoutCloseButton?: boolean;
    isSideModal?: boolean;
    numberOverlay?: number;
}

export const Modals: React.FC<IModals> = ({
    closeModal,
    className,
    title,
    children,
    closeButton,
    isOverlay = true,
    numberOverlay,
    overlayClick = true,
    isSideModal,
    iconTitle,
    withoutCloseButton,
}) => {
    const [isAnimation, setIsAnimation] = useState<boolean>(false);

    useEffect(() => {
        setIsAnimation(true);
        openModalStyles();
        return () => {
            closeModalStyles();
        };
    }, []);

    const openModalStyles = () => {
        document.body.style.overflow = "hidden";
    };

    const closeModalStyles = () => {
        document.body.style.overflow = "auto";
    };

    const handlerCloseModal = () => {
        setIsAnimation(false);
        if (closeModal) {
            setTimeout(() => {
                closeModal();
                closeModalStyles();
            }, 400);
        }
    };

    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    useOutsideClick(ref, handlerCloseModal);

    return (
        <>
            {isOverlay ? (
                <StyledOverlay isAnimation={isAnimation} $opacity={numberOverlay}>
                    <StyledModalWindow
                        $isSideModal={isSideModal}
                        ref={overlayClick ? ref : null}
                        className={className}
                        isAnimation={isAnimation}
                        data-testid="test-id-class"
                    >
                        <StyledTitleBox>
                            <StyledTitleIconBox>
                                {iconTitle && (
                                    <StyledIconBox>
                                        <StyledTitleIcon icon={iconTitle} />
                                    </StyledIconBox>
                                )}
                                <Typography variant={TypographyTypes.H3}>
                                    {title}
                                </Typography>
                            </StyledTitleIconBox>
                            {!withoutCloseButton && (
                                <StyledButtonClose
                                    onClick={handlerCloseModal}
                                    data-testid="close-button"
                                >
                                    <StyledButtonIcon icon={IconTypes.CLOSE} />
                                </StyledButtonClose>
                            )}
                        </StyledTitleBox>
                        {children}
                    </StyledModalWindow>
                </StyledOverlay>
            ) : (
                <StyledModalFixed
                    className={className}
                    isAnimation={isAnimation}
                    data-testid="test-id-class"
                >
                    {children}
                    {closeButton && (
                        <StyledButtonCloseBig
                            onClick={handlerCloseModal}
                            data-testid="button-test"
                        >
                            <Typography variant={TypographyTypes.BASE}>
                                {closeButton}
                            </Typography>
                        </StyledButtonCloseBig>
                    )}
                </StyledModalFixed>
            )}
        </>
    );
};
