import styled, { css } from "styled-components";
import { StylesToInput } from "styles/basic.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { InputStyleTypes } from "../input/input.types";
import { spacing } from "helpers/conversion-style";
import { media } from "lib/styled-components/variables";

interface IProps {
    error?: boolean;
    small?: boolean;
    $styleType: InputStyleTypes
}

export const StyledSelect = styled.select<IProps>`
    ${StylesToInput};
    width: 100%;
    border: 1px solid ${({theme, error}) => error ? theme.red?.[600] : theme.gray?.[300]};
    outline: none;
    margin-bottom: -1px;
    background-color: ${({theme}) => theme.bg?.default};
    color: ${({theme}) => theme.text?.default};
    appearance: none;
    ${({ $styleType }) => {
        switch ($styleType) {
            case InputStyleTypes.DEFAULT:
                return css<{small?: boolean;}>`
                    border-radius: 0;
                    height: ${({ small }) => small ? 60 : 78}px;
                    padding: ${({ small }) => small ? `${spacing(16)} ${spacing(8)}` : `${spacing(16)} ${spacing(40)} ${spacing(8)} ${spacing(8)}`};
                    border-radius: ${({ small }) => small && "12px"};
                `;
            case InputStyleTypes.AUTH:
                return css`
                    height: 68px;
                    border-radius: 8px;
                    padding: ${spacing(16)} ${spacing(8)} ${spacing(16)} ${spacing(42)};
                    ${media.smMax`
                        height: 60px;
                    `}
                `;
        }
    }};
`;

export const StyledSelectLabel = styled.div<{$styleType: InputStyleTypes}>`
    ${StylesToInput};
    color: ${({theme}) => theme.text?.muted};
    ${({ $styleType }) => {
        switch ($styleType) {
            case InputStyleTypes.DEFAULT:
                return css`
                    position: absolute;
                    top: 4px;
                    left: 8px;
                `;
            case InputStyleTypes.AUTH:
                return css`
                    display: block;
                    text-align: left;
                    margin-bottom: 8px;
                `;
        }
    }};
`;

export const StyledSelectIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    bottom: 18px;
    stroke: ${({theme}) => theme.text?.muted};
    pointer-events: none;
`;

export const StyledFlagIcon = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    bottom: 22px;
    overflow: hidden;
    border-radius: 50%;
    ${media.smMax`
        bottom: 18px;
    `}
`;
